import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import Nav from '../components/Nav';
import Footer from  '../components/Footer';
import './index.scss';
import { Widget } from '@typeform/embed-react';

// image imports
import ppe4all from '../assets/images/ppe4all.png'
import homepage from '../assets/images/ppe4all-krishna.jpeg'
import fist_bump from '../assets/images/happy-fist-bump.png'
import working from '../assets/images/working-hard.png'
import help1 from '../assets/images/help1.png'
import help2 from '../assets/images/help2.png'
import help3 from '../assets/images/help3.png'
import how1 from '../assets/images/how1.svg'
import how2 from '../assets/images/how2.svg'
import how3 from '../assets/images/how3.svg'
import how4 from '../assets/images/how4.svg'
import how5 from '../assets/images/how5.svg'
import how1mobile from '../assets/images/how1mobile.svg'
import how2mobile from '../assets/images/how2mobile.svg'
import how3mobile from '../assets/images/how3mobile.svg'
import how4mobile from '../assets/images/how4mobile.svg'
import family from '../assets/images/family.png'
import happykid from '../assets/images/happy-kid.png'
import socialinvestors from '../assets/images/socialinvestors.png'


const TypeformWidget = (isMobile) => {
  console.log(isMobile);
  return <Widget id="MQCC1JuE" style={isMobile ? {height: "70vh"} : {height: "0px"}} data-tf-inline-on-mobile/>
}

function Index() {
  const isBrowser = () => typeof window !== "undefined"

  let innerWidth;
  if (!isBrowser()) {
    innerWidth = 770;
  } else {
    innerWidth = window.innerWidth;
  }

  const [width, setWidth] = useState(innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
  
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
  
  const isMobile = width <= 768;

  return (
    <div>
      <Helmet title="PWR4" />

      {/* <Header /> */}
      <Nav className="navbar"/>

      <div>
        <div class="img-div">
          <img className="main-img" src={homepage} />
        </div>
      
      <div>

      </div>
        <div className="main_landing_title">
          <div id="charity"><span>The&nbsp;<text className="underline-effect">Charit</text>y
            <br />for&nbsp;<text className="underline-effect">Charities</text></span></div>
          <div id="main_des">PWR4 is a venture philanthropy group dedicated to providing sustainable financing and strategic support to revolutionary nonprofits.</div>
          <div id="nav-btn-div">
            <a href="#ask-us-anything">
              <button className="nav-btn" type="submit" onclick="#ask-us-anything" style={{minWidth: "14em"}}>Let's Talk</button>
            </a>
          </div>
        </div>
        <div className="vl"></div>
      </div>
      
      <div id="main">
        <section id="intro" className="main">
          <div className="spotlight">
            <div className="content">
              <div className='left-side'>
                <div className='image-stack'>
                  <div className='back-image'>
                    <img src={working} alt='image of someone paving a road'/>
                  </div>
                  <div className='front-image'>
                    <img className='front-img' src={fist_bump} alt='image of two people fist bumping'/>
                  </div>
                </div>
              </div>
              <div className={isMobile ? "" : "right-side"}>
                <h1 className="main-statement">
                  We're improving health outcomes for vulnerable populations, worldwide
                </h1>
                <div className='main-link'><a href='#ask-us-anything'>Help us work with non-profits</a></div>
              </div>
            </div>
          </div>
        </section>

        <section className="main" style={{width: "100%"}}>
          <h1 className="header-text">What do we do?</h1>
          <div className="section2">
            <div className={isMobile ? "slides" : "row"}>
              <div id="slide-1" className="col3">
                <img src={help1} alt="man doing volunteer work" />
                <div className="image-text">
                  <h2>Fund non-profit initatives</h2>
                  <h3>PWR4 provides unrestricted grants to partner nonprofits, allowing them to complete projects that directly improve health outcomes for those in need.</h3>
                </div>
              </div>
              <div id="slide-2" className="col3">
                <img src={help2} alt="child doing homework" />
                <div className="image-text">
                  <h2>Work directly with non-profits</h2>
                  <h3>Bottlenecks can hinder long-term growth. We work alongside our nonprofit partners to design, build, and integrate products and services for scaling impact.</h3>
                </div>
              </div>       
              <div id="slide-3" className="col3">
                <img src={help3} alt="zoom meeting" />
                <div className="image-text">
                  <h2>Provide impact transparency</h2>
                  <h3>Seeing is believing. We track our social impact in real time, and develop comprehensive reporting systems for each partnership.</h3>
                </div>                
              </div> 
            </div>
          </div>
        </section>

        <section id="section4" className="main">
          <div className={isMobile ? "" : "vertical-center outer"}>
            <div className={isMobile ? "" : "vertical-center inner"}>
              <h1 id="section4header" className="header-text">How does it work?</h1>
              <div className={isMobile ? "" : "row"}>
                <div className='col4'>
                  <img src={isMobile ? how1mobile : how1} alt="magnifying glass" />
                </div>
                {isMobile ? <br /> : <div className='colarrow'><img src={how5} alt="arrow" /></div>}
                <div className='col4'>
                  <img src={isMobile ? how2mobile : how2} alt="dollar sign" />
                </div>
                {isMobile ? <br /> : <div className='colarrow'><img src={how5} alt="arrow" /></div>}
                <div className='col4'>
                  <img src={isMobile ? how3mobile : how3} alt="two people" />
                </div>
                {isMobile ? <br /> : <div className='colarrow'><img src={how5} alt="arrow" /></div>}
                <div className='col4'>
                  <img src={isMobile ? how4mobile : how4} style={isMobile ? {marginBottom: "20px"} : null} alt="file" />
                </div>
              </div>
            </div>
          </div>
        </section>

      {/* TODO: add section below back in once we have pages set up */}
        {/* <section className="main">
          <div className="section4">
            <h1 className="header-text">Learn more</h1>
            <div className="row">
              <div className="col2">
                <img src={happykid} alt="cute kid eating food" />
                <div className="image-text">
                  <h2>Non-profit partners</h2>
                </div>   
              </div>
              <div className="col2">
                <img src={socialinvestors} alt="social investors chatting" />
                <div className="image-text">
                  <h2>Social investors</h2>
                </div>   
              </div>
            </div>
          </div>
        </section> */}

        <section className="main" id="ask-us-anything">
          <div>
          <h1 className="header-text">Contact us!</h1>
            <div className="typeform">
              <TypeformWidget isMobile/>
            </div>
          </div>
        </section>

        {/* <section id="cta" className="main special">
          <header className="major">
            <h2>Get in contact.</h2>
            <p>
              We are always looking for new partners as we continue to change the world of philanthropy to be more accessible and transparent.
            </p>
          </header>
          <footer className="major">
            <ul className="actions">
              <li>
                <a href="mailto:hello@pwr4.org">Contact us</a>
              </li>
            </ul>
          </footer>
        </section> */}
        <Footer/>
      </div>
    </div>
  )
}

export default Index;
