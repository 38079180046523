import React, {useState, Fragment} from 'react';
import logo from '../assets/images/pwr4logo.png';

const Nav = (props) => {
    const [colorChange, setColorchange] = useState(false);
    const isBrowser = () => typeof window !== "undefined"
    const changeNavbarColor = () =>{
        if(isBrowser() && window.scrollY >= window.innerHeight - (window.innerHeight / 7)){
          setColorchange(true);
        }
        else{
          setColorchange(false);
        }
     };
    if (isBrowser()) {
        window.addEventListener('scroll', changeNavbarColor);
    }
    return (
    <Fragment>
        <nav id="nav" className={(colorChange ? 'navbar colorChange alt' : 'navbar alt')}>
            <a href="/"><img src={logo} className="pwr4_logo"/></a>
            <div style={{paddingLeft: 250 + "px"}}> 
            {/* commented out temporarily as other pages are being built. TODO: build out pages */}
                {/* <li>
                    <a href="/nonprofit">For Non-profits</a>
                </li>
                <li>
                    <a href="/corporate">For Social Investors</a>
                </li>
                <li>
                    <a href="/about">About Us</a> 
                </li> */}
                <a href="#ask-us-anything">
                    <button className="nav-btn nav-btn2" type="submit">
                        Let's Talk
                    </button>
                </a>
            </div>
        </nav>
        <nav className="mobile-nav">
            <div>
            <a href="/"><img src={logo} className="pwr4_logo"/></a>
            <div> 
            {/* commented out temporarily as other pages are being built. TODO: build out pages */}
                {/* <li>
                    <a href="/nonprofit">For Non-profits</a>
                </li>
                <li>
                    <a href="/corporate">For Social Investors</a>
                </li>
                <li>
                    <a href="/about">About Us</a> 
                </li> */}
                <a href="#ask-us-anything">
                    <button className="nav-btn nav-btn2" type="submit">
                        Let's Talk
                    </button>
                </a>
                </div>
            </div>
        </nav>
    </Fragment>
    )

}

export default Nav;
